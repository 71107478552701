<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <h1 class="header-tablepage">Stock</h1>
        <div class="bg-white border-red p-3 p-lg-3 mt-3">
          <b-row>
            <b-col md="6" cols="12">
              <InputText
                :disabled="form.is_main > 0"
                textFloat="Name"
                isRequired
                placeholder="Name"
                type="text"
                v-model="form.name"
                :isValidate="$v.form.name.$error"
                :v="$v.form.name"
              ></InputText>
            </b-col>
            <b-col cols="12">
              <InputTextArea
                :disabled="form.is_main > 0"
                :rows="4"
                textFloat="Description"
                placeholder="Description"
                id="textarea-rows"
                v-model="form.description"
                :isValidate="$v.form.description.$error"
                :v="$v.form.description"
                isRequired
              />
            </b-col>
            <b-col cols="12">
              <b-form-checkbox
                :disabled="form.is_main > 0"
                switch
                v-model="form.is_allow_to_sale"
                class="radio-active"
                size="lg"
                :value="1"
                :unchecked-value="0"
              >
                <span class="ml-2 main-label">{{
                  form.active ? "Allow To Sale" : "Not Allow To Sale"
                }}</span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="12">
              <b-form-checkbox
                :disabled="form.is_main > 0"
                switch
                v-model="form.active"
                class="radio-active"
                size="lg"
                :value="1"
                :unchecked-value="0"
              >
                <span class="ml-2 main-label">{{
                  form.active ? "Active" : "Inactive"
                }}</span>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <FooterAction
      routePath="/setting/inventory/stock"
      @submit="submit(0)"
      :hideSubmit="form.is_main"
    />
  </div>
</template>

<script>
import InputTextArea from "@/components/inputs/InputTextArea.vue";
import OtherLoading from "@/components/loading/OtherLoading.vue";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    InputTextArea,
    OtherLoading,
  },
  validations: {
    form: {
      name: { required },
      description: { required },
    },
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        active: 0,
        is_allow_to_sale: 0,
      },
      isLoading: false,
    };
  },
  created() {
    if (this.$route.params.id != 0) this.getDetail();
  },
  methods: {
    async getDetail() {
      this.isLoading = true;
      let res = await this.axios.get("/setting/stock/" + this.$route.params.id);
      this.form = res.data.detail;
      this.isLoading = false;
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      this.$bus.$emit("showLoading");
      if (this.$route.params.id == 0) {
        let res = await this.axios.post("/setting/stock/create", this.form);
        if (res.data.result)
          this.successAlert(res.data.message).then(() =>
            this.$router.push("/setting/inventory/stock")
          );
        else this.errorAlert(res.data.message);
      } else {
        let res = await this.axios.put("/setting/stock/update", {
          ...this.form,
          id: this.$route.params.id,
        });
        if (res.data.result)
          this.successAlert(res.data.message).then(() =>
            this.$router.push("/setting/inventory/stock")
          );
        else this.errorAlert(res.data.message);
      }
      this.$bus.$emit("hideLoading");
    },
  },
};
</script>

<style></style>
